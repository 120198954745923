<template>
  <teleport to="body">
    <add-interview-policy-questions
      v-if="showModal"
      :animateButton="animateButton"
      @close-modal="addQuestionModal"
      @update="addQuestions"
    ></add-interview-policy-questions>
  </teleport>
  <div class="grid gap-5 mx-4 h-3/6 w-full">
    <div
      class="flex items-center text-teal ml-2 mt-3 cursor-pointer w-24"
      @click="reRoute"
    >
      <span class="material-icons-outlined"> arrow_back </span>
      go Back
    </div>
    <!-- Left Side -->
    <div class="col-span-2 grid">
      <!-- New Hire Section -->
      <div class="flex w-full my-2">
        <div class="bg-lightGrey rounded-xl py-2 w-full h-72">
          <div class="flex justify-between">
            <h3 class="text-lg text-grey pl-2 pt-1">
              Create New Interview Questionnaire
            </h3>
          </div>

          <div class="flex flex-col m-auto w-11.5/12">
            <div class="flex w-full mt-10 mb-5 items-center">
              <label for="jobTitle" class="w-3/12">Name:</label>

              <div class="w-9/12 md:w-11/12 relative">
                <input
                  v-model="name"
                  class="w-full h-10 rounded-md pl-2 input-style"
                  id="policy_name"
                  name="policy_name"
                  placeholder="Questionnaire Name"
                />
              </div>
            </div>
            <div class="flex w-full mt-10 mb-5 items-center">
              <label for="jobTitle" class="w-3/12">Role:</label>

              <div class="w-9/12 md:w-11/12 relative">
                <v-select
                  v-model="role"
                  :options="getRoles"
                  :filterable="true"
                  :placeholder="'Please select...'"
                  class="style-chooser"
                >
                  <template #list-footer>
                    <li v-show="rolesHasNextPage" ref="load" class="loader">
                      Loading more options...
                    </li>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Questions -->
      <div class="flex flex-col w-full mb-2 bg-lightGrey rounded-xl pb-2">
        <div class="flex row-span-1 w-full justify-between">
          <label class="text-lg text-grey pl-2">Questions</label>

          <base-button
            @click="addQuestionModal"
            backgroundColour="bg-orange hover:bg-orange-light mr-6"
            >Add Question
          </base-button>
        </div>
        <div class="flex flex-col pl-2 pr-2" v-if="questions.length > 0">
          <table class="text-center w-full text-grey my-2">
            <dynamic-table class="text-teal" :showTitle="false" title="">
              <interview-policy-question-table-item :data="questions">
              </interview-policy-question-table-item>
            </dynamic-table>
          </table>
        </div>
        <div v-else>
          <no-data-found></no-data-found>
        </div>
        <div class="flex justify-end">
          <base-button
            @click="savePolicy"
            backgroundColour="bg-green-500 hover:bg-green-600 mr-6"
            >Save Questionnaire
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dynamicTable from "@/components/ui/baseComponents/Tables/greyTable.vue";
import interviewPolicyQuestionTableItem from "@/components/ui/baseComponents/Tables/RE/interviewPolicyQuestionTableItem.vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import BaseButton from "@/components/ui/baseComponents/BaseButton.vue";
import addInterviewPolicyQuestions from "@/components/modal/interviewPolicy/addInterviewPolicyQuestions.vue";
import noDataFound from "@/components/ui/baseComponents/noDataFound.vue";
export default {
  provide() {
    return { headers: this.tableHeaders };
  },
  components: {
    BaseButton,
    addInterviewPolicyQuestions,
    dynamicTable,
    interviewPolicyQuestionTableItem,
    noDataFound,
  },
  mounted() {
    this.loadRoles;
  },
  data() {
    return {
      name: "",
      role: "",
      rolesPage: 1,
      roleData: [],
      showModal: false,
      tableHeaders: ["Question Type", "Section", "Question"],
      questions: [],
      animateButton: false,
    };
  },
  created() {
    this.loadRoles(this.rolesPage);
    this.observer = new IntersectionObserver(this.infiniteScroll);
    this.$store.dispatch("RePolicies/fetchInterviewPolicies");
  },
  methods: {
    reRoute() {
      this.$router.back();
    },
    async savePolicy() {
      const { name, role, questions } = this;
      if (name && role && questions) {
        const selectedRolesID = this.roles.find((item) => item.label === role.label);
        if (questions.length < 1) {
        this.$toast.error(
          `Add Questions.`
        );
        return;
      }
        const response = await this.$store.dispatch(
          "RePolicies/storeNewInterviewPolicy",
          {
            name,
            role: selectedRolesID.code,
            questions,
          }
        );
        if (response.status === 201) {
          this.$toast.success(`Interview Questionnaire Created Successfully`);
          this.$router.push({ name: "re-vnew-hire-policies" });
        }

        return;
      }
      if(!name){
        this.$toast.error(`Please input the Questionnaire name.`);
        return;
      }
      if(!role){
        this.$toast.error(`Please select the Questionnaire role.`);
        return;
      }
      if (questions.length < 1) {
        this.$toast.error(
          `Every Questionnaire must have at least one question.`
        );
        return;
      }
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        this.rolesPage++;
        this.loadRoles(this.rolesPage);
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async loadRoles(rolesPage) {
      try {
        await this.$store.dispatch("admin/loadRoles", rolesPage);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },

    addQuestionModal() {
      this.showModal = !this.showModal;
    },
    addQuestions(e) {
      this.showModal = !this.showModal;
      this.questions.push(e);
    },
  },
  computed: {
    getInterviewPolicies() {
      return this.$store.getters["RePolicies/getInterviewPolicies"];
    },
    roles() {
      return this.$store.getters["admin/getRoles"];
    },
    getRoles() {
      return this.roles;
    },
    rolesHasNextPage() {
      // Determine how many pages
      const totalPages = Math.ceil(this.roles.total / 10);
      if (this.rolesPage <= totalPages) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
/* #forHeight {
  height: calc(100vh - 84px);
} */
input[type="checkbox"]:checked ~ div {
  background-color: #ed8b00;
  color: white;
}
</style>
<style>
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  border: 1px solid #425563;
  height: 10rem;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
}
.style-chooser .vs__dropdown-toggle {
  height: 2.5rem;
  width: 100%;
}
.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: black;
}
.style-chooser-error .vs__dropdown-toggle,
.style-chooser-error .vs__dropdown-menu {
  border: 1px solid red;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
  height: 10rem;
}
.style-chooser-error .vs__dropdown-toggle {
  height: 2.5rem;
  width: 100%;
}
.style-chooser-error .vs__clear,
.style-chooser-error .vs__open-indicator {
  fill: black;
}

.input-style {
  border: 1px solid #000;
}
</style>
