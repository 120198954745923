<template>
  <tr
    v-for="item in data"
    :key="item.id"
    class="border-b hover:bg-orange-100 bg-gray-100 text-grey hover:bg-gray-200"
  >
    <td class="p-3 px-5">
      <p v-if="item.type">
        {{ this.formatString(item.type) }}
      </p>
    </td>
    <td class="p-3 px-5">
      <p v-if="headsArray[item.head]">
        {{ this.formatString(headsArray[item.head]) }}
      </p>
    </td>
    <td class="p-3 px-5 break-all overflow-y-scroll">
      <p class="text-left" v-if="item.question">
        {{ item.question }}
      </p>
    </td>
  </tr>
</template>

<script>
// import moment from "moment";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    link_1: {
      type: String,
    },
    link_2: {
      type: String,
    },
    myParameter_1: {
      type: String,
    },
    myParameter_2: {
      type: String,
    },
  },
  data() {
    return {
      headsArray: [
        "About the Candidate",
        "Role Specific Questions",
        "Questions from the Candidate",
      ],
    };
  },
  methods: {
    formatString(str) {
      const result = str.replace(/-/g, " ").replace(/^./, function (x) {
        return x.toUpperCase();
      });
      return result;
    },
  },
};
</script>
