<template>
  <form @submit.prevent>
    <div
      class="flex justify-center h-screen w-screen items-center z-20 absolute top-0 left-0 -translate-x-1/2"
    >
      <div
        class="h-screen w-screen bg-grey bg-opacity-60 absolute"
        @click="closeModal"
      ></div>
      <div
        class="hideScroll flex flex-col min-w-lg max-w-6xl mx-auto rounded-lg border border-lighGrey z-20"
      >
        <!-- Modal Body -->
        <div class="flex flex-col p-4 bg-white">
          <!-- Title -->
          <h2 class="font-semibold text-teal text-2xl self-center pb-2">
            Add Question
          </h2>
          <!-- Job Title / Contract Type -->
          <div
            class="flex flex-col items-center sm:flex-row my-1 sm:ml-2 md:ml-10"
          >
            <div class="w-full sm:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="question_type"
                >Question Type</label
              >
              <select
                name="question_type"
                id="question_type"
                class="rounded-lg border border-darkGrey bg-lightGrey w-full sm:w-11/12 h-10 pl-2"
                @change="onQuestionTypeSelect"
              >
                <option disabled selected value="">Choose an option</option>
                <option value="descriptive">Descriptive</option>
                <option value="single-choice">Single Choice</option>
                <option value="multi-choice">Multi Choice</option>
              </select>
            </div>
            <div class="w-full sm:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="head"
                >Section</label
              >
              <select
                name="section"
                id="section"
                class="rounded-lg border border-darkGrey bg-lightGrey w-full sm:w-11/12 h-10 pl-2"
                @change="onSectionSelect"
              >
                <option
                  :disabled="item.value === ''"
                  :selected="item.value === ''"
                  :value="item.value"
                  v-for="item in sectionOptions"
                  :key="item.title"
                >
                  {{ item.title }}
                </option>
              </select>
            </div>
          </div>
          <!-- Department / Reports To -->
          <div
            class="flex flex-col items-center sm:flex-row my-1 sm:ml-2 md:ml-10"
          >
            <div class="w-full pr-10">
              <label class="font-semibold text-grey flex pb-1" for="job_title"
                >Question</label
              >
              <input
                type="text"
                name="question"
                maxlength="250"
                v-model="question"
                class="rounded-lg border border-darkGrey bg-lightGrey w-full h-10 pl-2"
              />
            </div>
          </div>

          <div
            class="flex flex-col sm:flex-row my-1 sm:ml-2 md:ml-10 w-11/12 p-2 justify-end"
            v-if="haveOptions"
          >
            <button
              class="p-2 border rounded-md text-sm bg-orange text-white font-semibold"
              @click="addOptions"
            >
              Add Options
            </button>
          </div>
          <div
            class="flex flex-col items-start my-1 sm:ml-2 md:ml-10"
            v-if="haveOptions"
          >
            <div
              v-for="(option, k) in options"
              :key="k"
              class="flex flex-col sm:flex-row pb-1"
            >
              <div class="w-full pr-1">
                <input
                  type="text"
                  name="option"
                  maxlength="30"
                  v-model="option.option"
                  class="rounded-lg border border-darkGrey bg-lightGrey w-full h-9 pl-2"
                />
              </div>

              <div class="trashIconContainer cursor-pointer">
                <span
                  class="material-icons text-3xl"
                  @click="deleteRow(k, option)"
                  style="color: red"
                >
                  remove_circle_outline
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-row items-center justify-between p-5 bg-white border-t border-lightGrey rounded-bl-lg rounded-br-lg"
        >
          <p class="text-red-500 font-medium">{{ error }}</p>
          <div class="flex justify-center">
            <base-button
              :mode="'pause'"
              :animateButton="animateButton"
              @click="closeModal"
            >
              Cancel
            </base-button>
            <base-button :animateButton="animateButton" @click="submitModal">
              {{ animateButton ? "" : "Add" }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    animateButton: {
      type: Boolean,
      required: true,
    },
    sectionOptions: {
      type: Array,
      default: () => [
        { title: "Choose an option", value: "" },
        { title: "About the Candidate", value: "1" },
        { title: "Role Specific Questions", value: "2" },
        { title: "Questions From the Candidate", value: "3" },
      ],
    },
  },
  emits: ["close-modal", "submit-modal", "update"],
  created() {},
  data() {
    return {
      question: "",
      question_type: "",
      section: "",
      options: [],
      haveOptions: false,
      error: "",
    };
  },
  watch: {
    options: {
      handler() {
        this.error = null;
      },
      deep: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    submitModal() {
      const payload = {
        question: this.question,
        type: this.question_type,
        head: Number(this.section),
        options: this.options,
      };
      if (this.section.length && this.question_type.length && this.question) {
        if (this.question_type === "single-choice" && this.options.length < 2) {
          this.error =
            "single choice questions cannot have options less than 2";
          return;
        }
        if (this.question_type === "multi-choice" && this.options.length < 1) {
          this.error =
            "multiple choice questions cannot have options less than 1";
          return;
        }
        if (
          (this.question_type === "multi-choice" ||
            this.question_type === "single-choice") &&
          this.options.length > 0
        ) {
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].option === "") {
              this.error = "Enter option(s) value";
              return;
            }
          }
        }
        this.$emit("update", payload);
        return;
      }
      this.error =
        "please select a question type, section and input question text";
    },
    onQuestionTypeSelect(event) {
      this.error = null;
      let type = event.target.value;
      this.question_type = type;
      if (type === "single-choice" || type === "multi-choice") {
        this.haveOptions = true;
      } else {
        this.haveOptions = false;
      }
    },
    onSectionSelect(event) {
      this.error = null;
      let section = event.target.value;
      this.section = section;
    },
    addOptions() {
      this.options.push({ option: "" });
    },
    deleteRow(index, option) {
      var idx = this.options.indexOf(option);

      if (idx > -1) {
        this.options.splice(idx, 1);
      }
    },
  },
};
</script>
